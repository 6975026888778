
//liste des adresses sur Ropsten

/*const CrowsdaleContractAddress = "0xa5FF406b8f0CfD3bfe5b0B239aa2dE9fb04430f6";
const DAIContractAddress = "0x860aA68b0644b1533bb5E0a988Cf57B0eBdEb780";
const TokenContractAddress = "0x31474755Cd7d5Ff0E991D71C09c817B6930D2D29";
const WhiteListContractAddress = "0x36CbfA45E9d4737b01648fd459C50b41B42B75e7";

const CrowdSaleInitBlock = "10720878";*/

/*
Token contract:  0x35dc8dB29F91465304319ae4472C1B562a8B0D9f
Token contract proxy:  0x1732B0A35E4a59Df33f968995B8AAD1ccdBD5C25
Whitelist contract:  0xDF4B189a6641007dCb269Ef4Af5EAbeC5faa2bCa
crowdsale contract:  0x06f84b5c12c8510c1ae7676ed9dc4757c14d1648
crowdsale proxy:  0xF5A3AC72A8D98b20b55ccd942af9417ebf162CeD
payement contract:  0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee
owner:  0xE6585C6103f0972F408529b9C1452Ac0751b5699
*/

/*const CrowsdaleContractAddress = "0x2Cf7C950fBCD63DAE0960439E3C64F676aeCcdF5";
const DAIContractAddress = "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee";
const TokenContractAddress = "0xFD2a980C0C33319Fac391f39fc20B4C1917dfeb2";
const WhiteListContractAddress = "0x37f332915a14E5f0796B9c3aaa19cDf140f41262";*/

import { Trans } from "react-i18next";
import React from "react";

export const COMINIG_SOON_ACTIVATED = false;

//////////////////DeBUT TESTNET/////////////////////////


const CrowsdaleContractAddress = "0xF5A3AC72A8D98b20b55ccd942af9417ebf162CeD";
const DAIContractAddress = "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee";
const TokenContractAddress = "0xdb8d83dc28a108a1572f1d1e755c64612cc16e7a";
const WhiteListContractAddress = "0xDF4B189a6641007dCb269Ef4Af5EAbeC5faa2bCa";

const CrowdSaleInitBlock = "12134549";


const AppNetworkId = 97;

const AppNetworkName = "BSC testnet network";



export const AppNetworkIdEth = 3; 
export const ChainPropsEth ={ chainId: "0x3" };

//"* BEP20" -- > BSC
export const AppNetworkIdBsc = 97;
export const ChainPropsBsc ={
  chainId: "0x61",
  chainName: 'BSC Testnet',
  nativeCurrency: {
      name: 'BSC Testnet',
      symbol: 'BNB',
      decimals: 18,
  },
  rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  blockExplorerUrls: ['https://testnet.bscscan.com']
  // blockExplorerUrls: ['https://explorer.binance.org/smart-testnet']
};

const ChainProps ={
  chainId: "0x61", //open2web3.web3.utils.toHex(97)
  chainName: 'BSC Testnet',
  nativeCurrency: {
      name: 'BSC Testnet',
      symbol: 'BNB',
      decimals: 18,
  },
  rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  blockExplorerUrls: ['https://explorer.binance.org/smart-testnet']
};


const apiEndpoint = "https://app.swu.o2bprcl.xyz";
// const apiEndpoint = "https://app.swu.recette.o2bcloud.xyz";

const BlockPassId = "swuicodev_98ae0";
const blockExplorerUrl = "https://testnet.bscscan.com"



const open2web3Provider = new window.Open2web3.Open2web3Provider({
  icoClient: { uri: 'https://api.thegraph.com/subgraphs/name/chefhaker/icodevlbk' },
  tokenClient: { uri: 'https://api.thegraph.com/subgraphs/name/oussamachaabouni/swuerc20' },
  whitelistedClient: { uri: 'https://api.thegraph.com/subgraphs/name/chefhaker/whitelistdevlbk' }
});





//////////////////////////////FIN BSC TEST////////////////////////////////////////////////////



//liste des adresses sur BSC MAINNET
/*
const CrowsdaleContractAddress = "0xF5A3AC72A8D98b20b55ccd942af9417ebf162CeD";
const DAIContractAddress = "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee";
const TokenContractAddress = "0x958Cc5ac2eFa569CD9ad9B9B88245E1F038b02bE";
const WhiteListContractAddress = "0xDF4B189a6641007dCb269Ef4Af5EAbeC5faa2bCa";

const CrowdSaleInitBlock = "12134549";

const BlockPassId = "ic_kyc_swu_509cb";



export const AppNetworkIdEth = 1;
export const ChainPropsEth = { chainId: "0x1" };

//"* BEP20" -- > BSC
export const AppNetworkIdBsc = 56;
export const ChainPropsBsc = {
  chainId: "0x38", //open2web3.web3.utils.toHex(56)
  chainName: 'BSC',
  nativeCurrency: {
    name: 'BSC',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: ['https://bsc-dataseed.binance.org/'],
  blockExplorerUrls: ['https://bscscan.com']
};


const apiEndpoint = "https://app-swu.prod.open2be.com";
const blockExplorerUrl = "https://bscscan.com";

const open2web3Provider = new window.Open2web3.Open2web3Provider({
  icoClient: { uri: 'https://api.thegraph.com/subgraphs/name/chefhaker/icodevlbk' },
  tokenClient: { uri: 'https://api.thegraph.com/subgraphs/name/icoantsub/bep20-ant' },
  whitelistedClient: { uri: 'https://api.thegraph.com/subgraphs/name/chefhaker/whitelistdevlbk' }
});
*/
/////////////////////////// FIN BSC MAIN//////////////////////////////////////


//liste des adresses sur ETHEREUM
const CrowsdaleContractAddress_ETH = "0x20643edc0A25a73f681f92a8f426622068BF4665";
const USDTContractAddress_ETH = "0x337610d27c682e347c9cd60bd4b3b107c9d34ddd";
const TokenContractAddress_ETH = "0x0D4f68317fd24b0422F70353d16f369bE029cB4C";
const WhiteListContractAddress_ETH = "0x4734777462104BE0d9AF3E48AA16BF87F610663A";




const open2web3 = new window.Open2web3({
  addressCrowdfounding: CrowsdaleContractAddress,
  addressDAI: DAIContractAddress,
  adressToken: TokenContractAddress,
  adressWhiteList: WhiteListContractAddress,
});



const table_internationalisation = {
  /*pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect : 'Lines',
      nextAriaLabel : 'Page suivante',
      nextTooltip : 'Page suivante',
      lastAriaLabel : 'Dernière page',
      lastTooltip : 'Dernière page',
      firstAriaLabel : 'Première page',
      firstTooltip : 'Première page',
      previousAriaLabel : 'Page précédente',
      previousTooltip :'Page précédente'
  },
  toolbar: {
      nRowsSelected: '{0} ligne(s) sélectionné'
  },*/
  body: {
    emptyDataSourceMessage: <Trans i18nKey='no records to display' />,
  }
}

window._open2web3 = open2web3;


export const getOrCreateSignature = async function (address, signerAddress) {
  if (!address) return null;
  let signature = localStorage.getItem('signedAdressTransaction');
  if ((signature != null && signature != "") && signerAddress == localStorage.getItem('signerAdressTransaction')) {
    return signature;
  }
  //console.log("open2web3",open2web3.accounts[0])
  //var address = open2web3.accounts[0].substring(2);
  if (open2web3.web3.currentProvider.isMetaMask !== true) {
    signature = await window.BinanceChain.request({ method: "eth_sign", params: [signerAddress, address] });
  } else {
    signature = await open2web3.web3.eth.personal.sign(address, signerAddress);
  }
  localStorage.setItem('signedAdressTransaction', signature);
  localStorage.setItem('signerAdressTransaction', signerAddress);
  return signature;
}

export { BlockPassId, blockExplorerUrl, CrowdSaleInitBlock, apiEndpoint, open2web3, open2web3Provider, CrowsdaleContractAddress, DAIContractAddress, TokenContractAddress, WhiteListContractAddress, table_internationalisation };
