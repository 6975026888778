import { apiEndpoint, open2web3 } from "../common/open2web3";
import store from '../reducer/Store';

class AuthService /*extends Component*/ {

    constructor(/*props*/) {
        /*super(props)
        connect(mapState, mapDispatch)*/
    }

    getParentAddress () {
        let parrain = localStorage.getItem("parrainAddress");
        return parrain && parrain !== "" && parrain !== "undefined" ? parrain : null;
    }

    signUp (email, password, { publicAddress } = {}) {
        if (localStorage.getItem('lang') == null) {
            localStorage.setItem('lang', "FR");
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            hasAuth: false,
            body: JSON.stringify({
                username: email,
                password,
                lang: localStorage.getItem('lang').toUpperCase() ?? "FR",
                publicAddress
            })
        };
        return new Promise(async (resolve, reject) => {
            fetch(apiEndpoint + "/services/myUsers/subscribe?parrain=" + this.getParentAddress(), requestOptions)
                .then(response => {
                    if (response.ok) {
                        if (window.location.href.includes('reset-password')) {
                            window.location = '/';
                        }
                        resolve(response.json());
                    } else {
                        reject(response.json());
                    }

                })
                .catch(reject)
        });
    }

    signIn (email, password, { token } = {}) {
        return new Promise(async (resolve, reject) => {
            /*fetch(apiEndpoint + "/j_spring_security_check", {
                "headers": {
                    "content-type": "application/x-www-form-urlencoded",
                    "pragma": "no-cache",
                    "x-requested-with": "XMLHttpRequest"
                },
                "body": "j_username=" + `${email}` + "&j_password=" + `${password}` + "&j_rememberme=true",
                "method": "POST",
            }).then(responsex => {*/

            fetch(apiEndpoint + "/services/security/token", {
                "headers": {
                    'Content-Type': 'application/json',
                    'authorization': 'Basic ' + (btoa(`${email}:${password}`))
                },
                "body": null,
                "method": "GET",
                hasAuth: false
            }).then(tokenresponse => {

                if (tokenresponse.ok) {
                    tokenresponse.json().then(tokenObject => {
                        const token = tokenObject.WM_AUTH_TOKEN;

                        //store.dispatch({ type: SET_AUTH_TOKEN, payload: store.getState() });
                        localStorage.setItem('token', token);
                        const requestOptions2 = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'WM_AUTH_TOKEN': token
                            },
                            hasAuth: true,
                        };
                        fetch(apiEndpoint + "/services/myUsers/authenticate", requestOptions2)
                            .then(response => {
                                if (response.ok) {
                                    if (window.location.href.includes('reset-password')) {
                                        window.location = '/';
                                    }
                                    ///
                                    
                                    let ConfirmationCode = window.localStorage.getItem('TransactionConfirmationCode');
                                    if(ConfirmationCode!=null){
                                        window.location = '/myProfile';
                                    
                                    }
                                    resolve(response.json());
                                } else {
                                    reject(response.json());
                                }
                            })
                            .catch(reject)

                        /*const requestOptions2 = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'WM_AUTH_TOKEN': token.WM_AUTH_TOKEN
                            },
                        };
                        fetch(apiEndpoint + "/services/myUsers/authenticate", requestOptions2)
                            .then(response => {
                                if (response.ok) {
                                    resolve(response.json());
                                } else {
                                    reject(response.json());
                                }
                            })
                            .catch(reject)*/
                    })

                } else {
                    reject(tokenresponse.json());
                }

            })



            /*})
                .catch(reject)

            /**/
        });
    }


    sendPasswordResetEmail = (email) => {
        return fetch(apiEndpoint + `/services/email/sendPasswordResetEmail?email=${email}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
            hasAuth: false,
        });
    }

    checkTokenResetPassword = (token) => {
        return fetch(apiEndpoint + `/services/mySecurity/checkTokenResetPassword?token=${token}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token }),
            hasAuth: false,
        })
        .then(response => response.text());
    }

    resetPassword = (token, password) => {
        return fetch(apiEndpoint + `/services/mySecurity/password?token=${token}&password=${encodeURIComponent(password)}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, password }),
            hasAuth: false,
        });
    }


    async singTransaction (message) {
        console.log("singTransaction:open2web3.connect", message);
        const {
            web3,
            provider,
            address,
            chainId,
            networkId
        } = await open2web3.connect({ providerOptions: {} });
        var parentAddress = message;
        let signature;
        if (web3.currentProvider.isMetaMask !== true) {
            signature = await window.BinanceChain.request({ method: "eth_sign", params: [address, parentAddress] });
        } else {
            signature = await web3.eth.personal.sign(parentAddress, address);
        }
        localStorage.setItem('signedAdressTransaction',signature);
        localStorage.setItem('signerAdressTransaction',address);
        return signature
    }
    // POST https://www.wavemakeronline.com/run-x67hyc2l4k/SWU_ICO_master/services/email/sendPasswordResetEmail?email=cs9%40live.fr -> void
    // POST https://www.wavemakeronline.com/run-x67hyc2l4k/SWU_ICO_master/services/mySecurity/checkTokenResetPassword?token= -> (EXPIRED | VALIDE | INEXIST)
    // PUT https://www.wavemakeronline.com/run-x67hyc2l4k/SWU_ICO_master/services/mySecurity/password?token=a&password=f -> void
    async verifyUserCode (code, username) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            hasAuth: false,
        };
        return new Promise(async (resolve, reject) => {
            fetch(apiEndpoint + `/services/myUsers/confirmAccount?username=${username}&confirmationCode=${code}`, requestOptions)
                .then(response => {
                    if (response.ok) {
                        resolve(response.json());
                    } else {
                        reject(response.json());
                    }
                })
                .catch(reject)
        });
    }


    isConnectedViaEmail () {
        return store.getState().walletConnected && store.getState().user?.username;
    }

    getUsername () {
        return this.isConnectedViaEmail() ? store.getState().user?.username : store.getState().walletAddress;
    }

    isConnected (kyc) {
        return ((store.getState().walletConnected && store.getState().walletAddress) || this.isConnectedViaEmail()) && (kyc ? store.getState().kycValid : true)
    }


    getUsernameFromToken (token) {
        return atob(token).split(':')[0]
    }

}

/*const mapState = (store) => ({ store });
const mapDispatch = (dispatch) => ({ dispatch });*/

//export default connect(mapState, mapDispatch)(withTranslation()(new AuthService()));

export default new AuthService();
