
const initialState = {
  walletAddress: "",
  walletAddressShortForm: '',
  walletConnected: false,
  owner: "",
  isOwner: false,
  kycValid: false,
  myBalance: 0,
  availableTokens: 0,
  weiraised: 0,
  walletProvider: null,
  loading: false,
  infos: {},
  price: 0,
  deploymentEvent: {},
  networkId: null,
  key: 0,
  montantObjectif : 0,
  token:null,
  user:null,
  openAuthWindow: false,
  salesPercent : 0,
  salesPercentLoaded: false,
  totalPhase1 : 0,
  totalSold:0,
  isRefferalActor : true,
  myVerifiedBalance : 0,
  myUnverifiedBalance : 0,
  myVestedBalance : 0,
  fetchPendingNotifications : false,
  kycStatus:''
};

export const SET_WALLET = 'SET_WALLET';
export const SET_ISREFFERAL_ACTOR = 'SET_ISREFFERAL_ACTOR';
export const SET_TOKEN_INFOS = 'SET_TOKEN_INFOS';
export const SET_TOTAL_SUPPLY = 'SET_TOTAL_SUPPLY';
export const SET_TOTAL_PHASE1 = 'SET_TOTAL_PHASE1';
export const SET_SYMBOL = 'SET_SYMBOL';
export const SET_DECIMALS = 'SET_DECIMALS';
export const SET_MY_BALANCE = 'SET_MY_BALANCE';
export const SET_TOKEN_PRICE = 'SET_TOKEN_PRICE';
export const SET_DEPLOYMENT_INFO = 'SET_DEPLOYMENT_INFO';
export const SET_AVAILABLE_TOKENS = 'SET_AVAILABLE_TOKENS';
export const SET_OWNER_WALLET = 'SET_OWNER_WALLET';
export const SET_WEIRAISED = 'SET_WEIRAISED';
export const SET_LOADING = 'SET_LOADING';
export const SET_REFRESH = 'SET_REFRESH';
export const SET_KYC = 'SET_KYC';
export const SET_MONTANT_OBJECTIF = 'SET_MONTANT_OBJECTIF';
export const SET_DASHBOARD_LOADED = 'SET_DASHBOARD_LOADED';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_USER_NEW_ADDRESS = 'SET_USER_NEW_ADDRESS';
export const SET_OPEN_LOGIN_WINDOW = 'SET_OPEN_LOGIN_WINDOW';
export const SET_SALES_PERCENT = 'SET_SALES_PERCENT';
export const SET_TOTAL_SOLD = 'SET_TOTAL_SOLD';
export const SET_BALANCES_ACCOUNT = 'SET_BALANCES_ACCOUNT';
export const SET_FETCH_PENDING_NOTIFICATIONS = 'SET_FETCH_PENDING_NOTIFICATIONS';
export const KYC_STATUS = 'KYC_STATUS';

function MainReducer(state = initialState, action) {
  const { type, payload } = action;
  // console.log({ type, payload });
  switch (type) {
    case SET_WALLET:
      let walletAddressShortForm = '';
      if (payload.address) {
        let value = payload.address.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
        walletAddressShortForm = value[1]+'...'+value[3];
      }
      
      return { 
        ...state,
        walletAddress: payload?.address,
        walletAddressShortForm,
        walletProvider: payload?.provider,
        walletConnected: !!payload?.address,
        networkId: payload?.networkId,
        chainId:payload?.chainId,
        isOwner: state.owner && state.owner === payload?.address,
        token: state.token,
        user: state.user
      };
    
    case SET_ISREFFERAL_ACTOR : 
    return  { ...state, isRefferalActor : payload.isRefferalActor}
    case SET_FETCH_PENDING_NOTIFICATIONS : 
    return  { ...state, fetchPendingNotifications : payload.fetchPendingNotifications}
    case SET_OWNER_WALLET:
      return { ...state, owner: payload?.address, isOwner: payload?.address && payload?.address === state.walletAddress };

    case SET_LOADING:
      return { ...state, loading: !!payload };
  
    case SET_MY_BALANCE:
      return { ...state, myBalance: payload > 0 ? parseFloat(payload).toLocaleString() : 0 };

    case SET_WEIRAISED:
      return { ...state, weiraised: payload > 0 ? parseFloat(payload).toLocaleString() : 0 };
      
      case SET_TOTAL_SUPPLY:
      return { ...state, totalSupply: payload > 0 ? parseFloat(payload).toLocaleString() : 0 };

    case SET_SYMBOL:
      return { ...state, symbol: payload != null ? payload : "" };
  
    case SET_DECIMALS:
      return { ...state, decimals: payload > 0 ? payload : 18 };
      
    case SET_AVAILABLE_TOKENS:
      return { ...state, availableTokens: payload > 0 ? parseFloat(payload).toLocaleString() : 0 };

    case SET_TOKEN_INFOS:
      return { ...state, infos: { ...payload } };

    case SET_KYC:
      return { ...state, kycValid: !!payload };

    case SET_TOKEN_PRICE:
      return { ...state, price: payload > 0 ? (1 / parseFloat(payload/ (10 ** 6))).toLocaleString() : 0 };

    case SET_DEPLOYMENT_INFO:
      return { ...state, deploymentEvent: { ...payload } };
  
    case SET_REFRESH:
      return { ...state, key: state.key + 1 };
  
    case SET_MONTANT_OBJECTIF:
      return { ...state, montantObjectif: parseFloat(payload).toLocaleString() };
    
    case SET_DASHBOARD_LOADED:
        return { ...state, dashboardLoaded: payload };

    case SET_AUTH_TOKEN:
        return { ...state, token: payload };

    case SET_SALES_PERCENT:
        return { ...state, salesPercent: payload,salesPercentLoaded: true };

    case SET_USER:
      localStorage.setItem('user', JSON.stringify(payload?.user));
      let shortAddress = '';
      if (payload?.user?.publicAddress) {
        let value = payload.user.publicAddress.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
        shortAddress = value[1]+'...'+value[3];
      }
      return {... state, user: payload?.user, walletConnected: payload?.walletConnected ?? true, walletAddress: payload?.user?.publicAddress ?? state?.walletAddress,walletAddressShortForm:shortAddress}

    case SET_USER_NEW_ADDRESS:
      if(payload.walletAddress == undefined || payload.walletAddress == null) return {... state}
        let valueNewAddress = payload.walletAddress.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
        let sortForm = valueNewAddress[1]+'...'+valueNewAddress[3];
        
      
      return {... state,walletAddressShortForm:sortForm, walletAddress: payload?.walletAddress}

    case SET_OPEN_LOGIN_WINDOW:
      return {... state, openAuthWindow: payload}
    case SET_TOTAL_PHASE1:
      return {... state, totalPhase1: payload}
    case SET_TOTAL_SOLD:
      return {... state, totalSold: payload}
    case SET_BALANCES_ACCOUNT:
      return {... state, myVerifiedBalance: payload?.myVerifiedBalance,myUnverifiedBalance : payload?.myUnverifiedBalance,myVestedBalance:payload?.myVestedBalance}
      
    case KYC_STATUS:
        return { ...state, kycStatus: payload };

    default:
      return { ...state };
  }
}

export default MainReducer;
