import { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import WalletConnectButton from '../common/WalletConnectButton';
import KYCConnectButton from '../common/KYCConnectButton';
import { Modal, Spin } from 'antd';
import { apiEndpoint, blockExplorerUrl } from '../common/open2web3';
import AntBoxInfo from '../common/AntBoxInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMediumM, faInstagram, faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { Trans, withTranslation } from 'react-i18next';
import AuthUtils from '../services/authService';

class Layout extends Component {

  state = {
    isMobile: false
  }

  componentDidMount() {

    var isMobile = false;
    if (navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i) ||
      navigator.userAgent.match(/Opera Mini/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      isMobile = true
    } else {
      isMobile = false
    }
    this.setState({ isMobile: isMobile });
  }

  changeLang = (lang) => async() => {
    this.props.i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    if(AuthUtils.isConnected()){
      try{
        await fetch(apiEndpoint + `/services/myUsers/loggedInUserlanguage?lang=${lang}`,{
          method: 'PUT',
        });
      } catch(err){
          console.log(err);
      }
    }
  }

  render() {
    const connected = !!this.props.store.walletAddress || (!!this.props.store?.user?.username && this.props.store?.walletConnected);
    const isNotOwner = !this.props.store.owner || !this.props.store.walletAddress || this.props.store.owner !== this.props.store.walletAddress;
    return (
      <>
        <Spin spinning={this.props.store.loading && this.props.location.pathname !== '/'}>
          <main>
            <div className="top-nav">

              <nav className="navbar navbar-inverse">
                <div className="container-fluid pb-30">
                  <div className="navbar-header" style={{marginTop: "-5px" }}>

                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    <a href="https://www.swu-coin.com/"><img style={{ maxwidth: 216, marginRight: 25, height: 55, width: 'initial' }} src="/images/LogoN.png" alt="SWU ICO" className="dM-none" /></a>
                    {/*partie mobile*/}

                    <ul className="navM dL-none dipad-none">
                      <li><a href="https://www.swu-coin.com/"><img style={{ height: 70 }} src="/images/LogoN.png" className="logo" alt="SWU ICO" /></a></li>

                      <li>SWU <span className="priceS"><AntBoxInfo.Price valueOnly /></span></li>
                     
                    </ul>


                  </div>

                  <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul className="nav navbar-nav nav1">
                      {/*<li className={this.props.location.pathname === '/' ? 'active' : ''} ><Link to="/"> <span className="glyphicon glyphicon-th-large"></span>  <Trans i18nKey="Dashboard" /></Link></li>*/}
                      {connected && <li className={this.props.location.pathname === '/token-sale' ? 'active' : ''}><Link
                          to="/token-sale"> <span className="glyphicon glyphicon-usd"></span> <Trans
                          i18nKey="Token Sale"/></Link></li>}
                      {connected && isNotOwner ? <li className={this.props.location.pathname === '/referral' ? 'active' : ''} ><Link to="/referral"> <span className="glyphicon glyphicon-ok-sign"></span> <Trans i18nKey="Referral" /><span className="visible-text3" style={{fontSize:14}}> « Beta »</span></Link></li> : null}
                      {connected && isNotOwner ? <li className={this.props.location.pathname === '/myProfile' ? 'active' : ''} ><Link to="/myProfile"> <span className="glyphicon glyphicon-user"></span> <Trans i18nKey="My Wallet" /><span className="visible-text3" style={{fontSize:14}}> « Beta »</span></Link></li> : null}
                      {connected && isNotOwner ? <li className={this.props.location.pathname === '/staking' ? 'active' : ''} ><Link to="/staking"> <span className="glyphicon glyphicon-oil"></span> <Trans i18nKey="Staking" /><span className="visible-text3" style={{fontSize:14}}> « Beta »</span></Link></li> : null}
                      {/*<li className={this.props.location.pathname === '/whitepaper' ? 'active' : ''} ><Link to="/whitepaper"> <span className="glyphicon glyphicon-road pr-5"></span> <Trans i18nKey="White Paper" /></Link></li>*/}
                      {/* <li className={this.props.location.pathname === '/vote' ? 'active' : ''} ><Link to="/vote"> <span className="glyphicon glyphicon-inbox"></span> Vote</Link></li> */}
                      {/* <li className={this.props.location.pathname === '/properties' ? 'active' : ''} ><Link to="/properties"> <span className="glyphicon glyphicon-stats"></span> Properties</Link></li> */}
                      {isNotOwner ? null : <li className={this.props.location.pathname === '/admin' ? 'active' : ''} ><Link to="/admin"> <span className="glyphicon glyphicon-th-large"></span> <Trans i18nKey="Admin" /></Link></li>}
                      <li className="hiddenMo"><Link onClick={this.changeLang('fr')}><img style={{ height: 24 }} src="/images/fr.png" alt="fr" /></Link></li>
                      <li className="hiddenMo"><Link onClick={this.changeLang('en')}><img style={{ height: 24 }} src="/images/en.png" alt="en" /></Link></li>
                      <li className="hiddenMo"><Link onClick={this.changeLang('es')}><img style={{ height: 24 }} src="/images/es.png" alt="es" /></Link></li>

                      <div className="hiddenL">
                      <li><Link onClick={this.changeLang('fr')}><img style={{ height: 24 }} src="/images/fr.png" alt="fr" /></Link></li>
                      <li><Link onClick={this.changeLang('en')}><img style={{ height: 24 }} src="/images/en.png" alt="en" /></Link></li>
                      <li><Link onClick={this.changeLang('es')}><img style={{ height: 24 }} src="/images/es.png" alt="es" /></Link></li>
                      </div>
                      
                      {/* <li><Link to="/token-sale" ><span className="BtnH hiddenL disabled2"><Trans i18nKey="Buy tokens" /></span></Link></li> */}

                     
                      
                    </ul>

                    {/*partie Desktop*/}
                    <ul className="nav navbar-nav nav2 navbar-right d-sm-none d-md-block">
                      {/* <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        FR
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="#">Français</a>
                        <a className="dropdown-item" href="#">Anglais</a>
                        <a className="dropdown-item" href="#">Tailand</a>
                        <a className="dropdown-item" href="#">Arabe</a>
                      </div>
                    </li> */}





                      {/*<li className="dM-none"><a><span>SWU <span className="priceS"><AntBoxInfo.Price valueOnly /></span></span></a></li>*/}
                     {/* <li className="positionB dM-none"> <Link to="/token-sale"><span className="BtnH disabled2"><Trans i18nKey="Buy tokens" /></span></Link></li> */} 








                      {this.props.store.walletAddress ?
                        <li><a target="_blank" rel="noopener noreferrer" href={`${blockExplorerUrl}/address/${this.props.store.walletAddress}`}>{this.props.store.walletAddressShortForm}</a></li>
                        : null}
                      {!this.props.store.walletAddress && this.props.store.user?.username ?
                          <li><a target="_blank" rel="noopener noreferrer" >{this.props.store.user?.username?.split('@')[0]}</a></li>
                          : null}
                      <li><WalletConnectButton /></li>

                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <KYCConnectButton />
            <div className="content">
              {this.props.children}
            </div>
            <footer className="sFooter">

              <span className="copy_rigth">&copy; Copyright SWU 2022 All Rights Reserved </span>
              <span className="socials-media-icons">

                <a href="https://www.facebook.com/SMARTWORLDUNION" target="_blank"><FontAwesomeIcon icon={faFacebook} size="lg" /></a>
                <a href="https://www.instagram.com/switch.ub.company/" target="_blank"><FontAwesomeIcon icon={faInstagram} size="lg" /></a>
                <a href="https://twitter.com/SMARTWORLDUNION" target="_blank"><FontAwesomeIcon icon={faTwitter} size="lg" /></a>
                <a href="https://medium.com/@smartworldunion" target="_blank"><FontAwesomeIcon icon={faMediumM} size="lg" /></a>
              </span>
              <div>
                <a className="t_and_c" href="https://www.swu-coin.com/politique-de-confidentialite" target="_blanc"><Trans i18nKey="Terms and conditions" /></a>
                <a className="t_and_c" href="https://www.swu-coin.com/mentions-legales" target="_blanc"><Trans i18nKey="Legal Notice" /></a>
              </div>
            </footer>
          </main>
        </Spin>


        <Modal className="colorT" visible={this.state.isMobile} title="Information" footer={<div style={{ display: 'flex', justifyContent: 'center' }}><button className="BtnH" onClick={e => this.setState({ isMobile: false })} >Ok</button></div>} onOk={e => this.setState({ isMobile: false })} onCancel={e => this.setState({ isMobile: false })} >
          <p><Trans i18nKey="For investment, we recommend using Firefox or Chrome on your computer" /></p>

        </Modal>
      </>
    )
  }
}


const mapStateToProps = (store) => ({ store });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Layout)));
