import { connect } from 'react-redux';
import { useEffect, lazy } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Layout from './layout/Layout';
import { Admin } from './pages';
import { SET_USER } from "./reducer/Main.reducer";
import Staking from "./pages/Staking";

const TokenSaleLazy = lazy(()=>import('./pages/TokenSale'));
const MonCompteLazy = lazy(()=>import('./pages/MonCompte'));
const ProfileLazy = lazy(()=>import('./pages/Profile'));
const VerifyAccountLazy = lazy(()=>import('./pages/AccountVerification'));
const ResetPassword = lazy(()=>import('./pages/ResetPassword'));

function App ({ store, dispatch }) {

    useEffect(
        () => {
            let savedUser = localStorage.getItem('user');
            if(savedUser !== undefined && savedUser !== '' && savedUser !== 'undefined' && savedUser !== 'null'){
                let newUser = {
                    ...JSON.parse(savedUser),
                }
                if(!newUser?.username) return;
                dispatch({type: SET_USER, payload:{user: newUser, walletConnected: true}})
            }
            return () => {

            };
        },
        []
    );

  const connected = !!store.walletAddress || (!!store?.user?.username && store?.walletConnected);
  const isNotOwner = !store.owner || !store.walletAddress || store.owner !== store.walletAddress;
  return (
    <BrowserRouter>
        <Layout>
            <Switch>
                <Route exact path="/verify-account" component={VerifyAccountLazy} />
                <Route exact path="/reset-password" component={ResetPassword} />
                {/*<Route exact path="/" component={Dashboard} />*/}
                {connected && isNotOwner ? <Route exact path="/referral" component={MonCompteLazy} /> : null}
                {connected && isNotOwner ? <Route exact path="/myProfile" component={ProfileLazy} /> : null}
                {connected && isNotOwner ? <Route exact path="/staking" component={Staking} /> : null}
                <Route path="/token-sale" component={TokenSaleLazy}  />
                {/*<Route exact path="/vote" component={Vote} />
                <Route exact path="/properties" component={Properties} />*/}
               {/* <Route exact path="/whitepaper" component={Whitepaper} />*/}
                { isNotOwner ? null : <Route exact path="/admin" component={Admin} /> }
                { isNotOwner ? <Redirect to="/token-sale" /> : <Redirect to="/admin" /> }
            </Switch>
        </Layout>
    </BrowserRouter>
  );
}

const mapState = (store) => ({ store });
const mapDispatch = (dispatch) => ({ dispatch });

export default connect(mapState, mapDispatch)(App);
