import MaterialTable from "material-table";
import { Component, forwardRef } from "react";
import { apiEndpoint } from "../common/open2web3";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { withTranslation } from "react-i18next";
import AntBoxInfo from "../common/AntBoxInfo";
import { Button } from "antd";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class Staking extends Component{
    
    state = {
        staking: [],
        totalRendemant: null,
        redemantActuel: null,
        soldSWU: null,
        urlFrontStaking:""
    }
   
    
    componentDidMount(){
        fetch( apiEndpoint + "/services/rendement/rendementListOfConnectedUser")
        .then(response => response.json())
        .then(json => this.setState({staking:json}));

        fetch( apiEndpoint + "/services/rendement/totalRendementOfConnectedUser")
        .then(response => response.json())
        .then((data) => this.setState({totalRendemant:parseFloat(data.totalRendement.toFixed(2)).toLocaleString()}));

        fetch( apiEndpoint + "/services/rendement/pourcentageRevenueAnnuelRendement")
        .then(response => response.json())
        .then((data) => this.setState({redemantActuel:parseFloat(data.toFixed(2)).toLocaleString()}));

        fetch( apiEndpoint + "/services/rendement/totalStackedConnectedUser")
        .then(response => response.json())
        .then((data) => this.setState({soldSWU:parseFloat(data.toFixed(2)).toLocaleString()}));
        this.getFrontStakingUrl();
    }
    componentDidUpdate(){
        // this.getFrontStakingUrl();
    }
    btnVistStakingv2Handler=()=>{
        // console.log('stat',this.state.urlFrontStaking);
        let CurrentLanguage = localStorage.getItem('lang');
        let Query  ='';
        if(CurrentLanguage!=null){
            Query='?lang='+CurrentLanguage
        }
        window.open(
            this.state.urlFrontStaking+Query, "_blank");
        
    }
     getFrontStakingUrl=()=>{
         fetch(apiEndpoint+"/services/LabarakIcoDB/queryExecutor/queries/getUrlFrontStaking")
        .then(response => response.json())
        .then(data=> this.setState({urlFrontStaking:data.urlFrontStaking}))
      
       
    }

    render(){
        const table_internationalisation = {
            body: {
              emptyDataSourceMessage: this.props.t('no records to display')
            }
          }
        return(
            <div className="container tabStack pt-50">
            <div className="row pt-50">
                
                <div className={"col-xs-12 col-sm-12 col-md-3 col-lg-3"}>
                    <AntBoxInfo.totalRendemant initialValue={this.state.totalRendemant} />
                </div>
                <div className={"col-xs-12 col-sm-12 col-md-3 col-lg-3"}>
                    <AntBoxInfo.rendementActuelle initialValue={this.state.redemantActuel + '% ' + this.props.t("Annuel")} />
                </div>
                {/* <div className={"col-xs-12 col-sm-12 col-md-3 col-lg-3"}>
                    <AntBoxInfo.SoldeSWU initialValue={this.state.soldSWU} />
                </div> */}
                <div className={"col-xs-12 col-sm-12 col-md-3 col-lg-3"}>
                <div className="AntBoxInfo2 btnPr3"><Button onClick={this.btnVistStakingv2Handler}> {this.props.t("Accéder au staking Post-ICO")}</Button></div>

                    </div>
                </div>
                <h2 className="section-title w-700">{this.props.t("Distribution rendement")}</h2>
            <MaterialTable
            
                icons={tableIcons}
                // date , sold swu Total achat,randement gagne (swu)
                columns={[
                    {
                        title: this.props.t("Date"),
                        field: "rendementDate"
                    },
                    {
                        title: this.props.t("Solde SWU"),
                        field: "totalAchat",
                        render: row => {
                            return parseFloat(row.totalAchat).toFixed(2);
                        }
                    },
                    {
                        title: this.props.t("Pourcentage"),
                        field: "pourcentageRendement",
                        render: row => {
                            return parseFloat(row.pourcentageRendement).toFixed(2) + " %";
                        }
                    },
                    {
                        title: this.props.t("Rendement gagné"),
                        field: "rendementAmount",
                        render: row => {
                            return parseFloat(row.rendementAmount).toFixed(2);
                        }
                    }

                ]}
                data={this.state.staking}
                options={{
                    toolbar: false
                }}
                localization = {table_internationalisation}

            />
            </div>
        );
    }
}
export default withTranslation()(Staking);